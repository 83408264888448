import { useState, useEffect } from 'react'
import { Event } from './event-tracking'

const getDocumentHeight = () => {
  return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
  )
}

const ScrollTracking = () => {
  const [percent, setPercent ] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', sendScrollDepthEvent)
    return () => {
      window.removeEventListener('scroll', sendScrollDepthEvent)
    }
  })

  const sendScrollDepthEvent = () => {
    const windowDocumentGlobal = typeof window !== 'undefined' && typeof(document) !== 'undefined'
    if (windowDocumentGlobal) {
      const windowHeight = window.innerHeight || (document.documentElement || document.body).clientHeight
      const documentHeight = getDocumentHeight()
      const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
      const trackLength = documentHeight - windowHeight
      const percentScrolled = Math.floor(scrollTop/trackLength * 100)
      const page = window.location.pathname + window.location.search + window.location.hash

      if (percentScrolled < 50 && percentScrolled >= 25 && percent === 0) {
        setPercent(25)
        Event('Scroll-Depth', page, '25', 25)
      } else if (percentScrolled < 75 && percentScrolled >= 50 && percent === 25){
        setPercent(50)
        Event('Scroll-Depth', page, '50', 50)
      } else if (percentScrolled < 100 && percentScrolled >= 75 && percent === 50){
        setPercent(75)
        Event('Scroll-Depth', page, '75', 75)
      } else if (percentScrolled >= 95 && percent === 75){
        setPercent(95)
        Event('Scroll-Depth', page, '95', 95)
      }
    }
  }

  return null
}

export default ScrollTracking