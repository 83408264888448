import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CLOSE_MENU } from 'store/actions'
import { SET_BODY_OVERFLOW } from 'store/actions'
import { sendClickedEvent } from 'helpers/analytics/event-tracking'

class ScrollButton extends Component {
  constructor(){
    super()
  }

  handleClick = (event, eventFirst) => {
    event.preventDefault()
    sendClickedEvent(event)
    if (eventFirst) {
      eventFirst(event)
    }
    let elem = document.getElementById(
      event.currentTarget.hash.split('#').pop()
    )
    if (elem) {
      elem.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
      this.props.closeMenu()
    }
  }

  render () {
    return (
      <a
        href={this.props.to}
        onClick={(e) => this.handleClick(e, this.props.eventFirst)}
        className={this.props.className}
        id={this.props.id ? this.props.id : null}
        >
        {this.props.children}
      </a>
    )
  }

}

const mapDispatchToProps = dispatch => {
  return {
    closeMenu: () => {
      dispatch(CLOSE_MENU)
      dispatch(SET_BODY_OVERFLOW(false))
    },
  }
}

export default connect(null, mapDispatchToProps)(ScrollButton)
