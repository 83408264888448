import React from 'react'

const DownArrow = () => (
    <svg className="ml-1 mb-1" width='10' height='7' viewBox='0 0 10 7' xmlns='http://www.w3.org/2000/svg'>
        <g id='symbols' fill='none' fillRule='evenodd'>
            <g id='Selector-Bar' transform='translate(-374 -57)' fill='#FFF'>
                <g id='selector-/-item' transform='translate(210 -1)'>
                    <path d='M8.73647893,5.85922541 L12.6274848,10.3987323 C12.9761243,10.8054784 12.9290197,11.4178394 12.5222736,11.7664789 C12.3464677,11.9171696 12.1225559,12 11.8910059,12 L4.10899408,12 C3.57327786,12 3.13899405,11.5657162 3.13899405,11.03 C3.13899405,10.79845 3.22182445,10.5745381 3.37251515,10.3987323 L7.26352107,5.85922541 C7.61216057,5.45247933 8.22452157,5.40537464 8.63126765,5.75401414 C8.6689801,5.78633909 8.70415397,5.82151297 8.73647893,5.85922541 Z'
                    transform='rotate(-180 88.5 35.25)' id='icon-/-drop-down-/-closed' />
                </g>
            </g>
        </g>
    </svg>
)

export default DownArrow
