import React from "react";

const MobileMenuButton = (props) => {
  let mobileMenuIcon;
  if (props.menuOpen) {
    // X symbol
    mobileMenuIcon =
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        fill="#112131"
        data-name="Layer 1"
        viewBox="0 0 335.64 335.64"
      >
        <path d="M190.61 145.41l135 135c13.49 13.51 13.22 34.07-.44 46.78a32.19 32.19 0 01-42.88.35c-.87-.78-1.68-1.62-2.51-2.44Q145.16 190.47 10.48 55.77c-13.18-13.19-14-32.6-2-45.38a31.86 31.86 0 0146.05-1.06c19.42 19.23 38.68 38.62 58 57.95z"></path>
        <path d="M190.23 190.7q-67.35 67.34-134.69 134.66c-13.69 13.67-34 13.68-46.8.19a32.09 32.09 0 01-.6-43.23c.56-.62 1.14-1.21 1.73-1.8Q145.26 145.13 280.66 9.76c12.57-12.57 32.11-13 44.65-1.26a31.94 31.94 0 011 46.08c-17.33 17.54-34.85 34.9-52.28 52.34z"></path>
      </svg>
  } else {
    // =  hamburger symbol
    mobileMenuIcon =
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path  d="M4 10h24a2 2 0 000-4H4a2 2 0 000 4zm24 4H4a2 2 0 000 4h24a2 2 0 000-4zm0 8H4a2 2 0 000 4h24a2 2 0 000-4z"></path>
    </svg>
  }

  return (
    <div className={props.menuOpen ? 'mr-3' : null}>
      {mobileMenuIcon}
    </div>
  )
}

export default MobileMenuButton;
