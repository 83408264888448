import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import Address from 'components/global/address'
import SiteMap from './../sitemap/sitemap'
import './footer.sass'
import Logo from "../header/logo";

const Footer = ({ footer_site_map, className }) => {
  let data = useStaticQuery(graphql`
    query {
      mainYaml {
        socialLinks {
          link
          name
          icon
        }
      }
    }
  `)

  const footer_social_links = data.mainYaml.socialLinks.map(({link, icon, name}, i) => {
    return (
      <li key={i}>
        <a href={link} target="_blank" rel="noopener noreferrer"><img  src={require(`images/icons/${icon}`).default} width="25" height="25" alt={name}/></a>
      </li>
    )
  })

  return (
    <footer className={`Footer text-center small ${className ?? null}`}>
      <div className="container-fluid">
        <div className="d-lg-flex p-0">
          <div id="Footer__about" className="text-left">
            <div className="d-flex align-items-center">
              <Logo className="Footer__logo"/>
              <Link
                to="/"
                className="k-text--mono ml-3 text-decoration-none Footer__logo-text">
                Kortical
              </Link>
            </div>
            <h4 className="k-text--mono mt-4">Kortical’s mission is to help you build the AI future.</h4>
            <p>Our unified AI cloud platform empowers data science teams to own ML models from raw data, feature engineering, model building, through to scalable ML app deployment. The impact of using Kortical is a higher AI project success rate, in less time, whilst removing large portions of the operational risk.</p>
            <p>Kortical is the AI platform data scientists love to use.</p>
            <ul className="Footer__social px-0 k-bullets-none flex-wrap d-flex mb-4">
              {footer_social_links}
            </ul>
          </div>
          <nav className="flex-grow-1">
            {footer_site_map ? <SiteMap/> : null}
          </nav>
        </div>
        <ul className={`list-unstyled text-left ${footer_site_map ? 'mt-5' : null}`}>
          <li className="d-md-inline-block p-0">
            <a
              id="Footer__login"
              href={process.env.PLATFORM_HOST}
              className="py-2 pr-3 px-0"
              rel="noopener noreferrer">
              Login
            </a>
          </li>
          <li className="d-md-inline-block p-0">
            <Link
              id="Footer__privacy-policy"
              to="/legal/privacy-policy"
              className="py-2 pr-3 p-0">
              Privacy Policy
            </Link>
            <Link
              id="Footer__company-policies"
              to="/company-policies"
              className="py-2 pr-3 p-0">
              Company Policies
            </Link>
            <Link
              id="Footer__terms-and-conditions"
              to="/legal/terms-and-conditions"
              className="py-2 pr-3 p-0">
              Terms &amp; Conditions
            </Link>
          </li>
        </ul>
        <p className="mb-0 text-left">
          Copyright &copy; {new Date().getFullYear()} Kortical Ltd. All rights reserved.<br/>
          Registered in England &amp; Wales No.10452273 | <Address/>
        </p>
      </div>
    </footer>
  )
}

export default Footer
