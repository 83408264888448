import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'
import DownArrowThin from 'images/icons/down-arrow--thin.svg'
import './sitemap.sass'

const SiteMapSectionLinks = ({ link: {link, name}, selected, index, is_mobile }) => {
  let class_is = null

  if (is_mobile) {
    class_is = selected === index ? 'd-block' : 'd-none'
  }

  return (
    <li className={class_is}>
      <Link to={link}> {name} </Link>
    </li>
  )
}

const SiteMapSectionItem = ({ section, index, selected, setSelected }) => {
  const sectionLinkList = section.links.map((link, i) => (
      <React.Fragment key={i}>
        <div className="SiteMapSectionLinksDesktop">
          <SiteMapSectionLinks link={link}
                               selected={selected}
                               index={index}
                               is_mobile={false}/>
        </div>
        <div className="SiteMapSectionLinksMobile">
          <SiteMapSectionLinks link={link}
                               selected={selected}
                               index={index}
                               is_mobile={true}/>
        </div>
      </React.Fragment>
  ))

  const accordian = () => {
    if (selected === index) {
      setSelected(null)
    } else {
      setSelected(index)
    }
  }

  return (
    <li className="SiteMapSectionItem">
      <p className="d-flex p-2" onClick={() => accordian()}>
        {section.title}
        <img src={DownArrowThin} className={`${selected === index ? 'SiteMapSectionItem__arrow--down' : 'SiteMapSectionItem__arrow--up'}`}/>
      </p>

      <ul className={`px-0 k-bullets-none flex-wrap ${section.title === 'Follow Us' ? 'd-flex' : null} ${selected === index ? 'py-3' : null}`}>
        {sectionLinkList}
      </ul>
    </li>
  )
}

const SiteMap = () => {
  const [ selected, setSelected] = useState(null)

  let data = useStaticQuery(graphql`
    query SiteMaQuery {
      componentsYaml {
        sections {
          title
          links {
            link
            name
          }
        }
      }
    }
  `)

  const sectionList = data.componentsYaml.sections.map((section, i) => {
    return <SiteMapSectionItem section={section}
                               index={i}
                               selected={selected}
                               setSelected={setSelected}
                               key={i}/>
  })

  if (data) {
    return (
      <ul className="d-md-flex p-0 justify-content-md-around k-bullets-none">
        {sectionList}
      </ul>
    )
  } else {
    return null
  }
}

export default SiteMap