/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import defaultOgImg from 'images/content/og-default/og-default.jpg'

function SEO({ description, lang, meta, keywords, title, image, metaTitle }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || defaultOgImg

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title !== "Home" ? `%s | ${site.siteMetadata.title}` : site.siteMetadata.title }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle || title,
          prefix: `og: http://ogp.me/ns#`,
        },
        {
          property: `og:description`,
          content: metaDescription,
          prefix: `og: http://ogp.me/ns#`,
        },
        {
          property: `og:type`,
          content: `website`,
          prefix: `og: http://ogp.me/ns#`,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.url}${metaImage}`,
          prefix: `og: http://ogp.me/ns#`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'robots',
          content: 'max-image-preview:large'
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
