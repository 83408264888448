export const TOGGLE_MENU = {
  type: 'TOGGLE_MENU',
}

export const CLOSE_MENU = {
  type: 'CLOSE_MENU',
}

export const SET_BODY_OVERFLOW = (payload) => {
  return {
    type: 'SET_BODY_OVERFLOW',
    data: payload,
  }
}

export const ADD_OR_UPDATE_SECTION_TRACKING = (section) => {
  return {
    type: 'ADD_OR_UPDATE_SECTION_TRACKING',
    data: section
  }
}

export const UPDATE_TOTAL_TIMES_SECTION_TRACKING = () => {
  return {
    type: 'UPDATE_TOTAL_TIMES_SECTION_TRACKING'
  }
}

export const UPDATE_PLATFORM_REGISTRATION = (field, value) => {
  return {
    type: 'UPDATE_PLATFORM_REGISTRATION',
    field,
    value
  }
}