import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Address = () => (
  <StaticQuery query={
      graphql`
        query AddressQuery {
          mainYaml {
            address {
              link
              text
            }
          }
        }
      `
    }
    render={ data => (
      <a
        href={data.mainYaml.address.link}
        rel="noopener noreferrer"
        target="_blank">
        {data.mainYaml.address.text}
      </a>
    )}
  />
)

export default Address
