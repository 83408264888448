import { useEffect } from 'react';

const useChatbotScript = () => {
  useEffect(() => {
    const client_id = 'kortical';
    const chatbot_id = 'b3f41b59-de37-4ef0-b64e-8e1363887e71';

    const script_id = 'kortical_chat_kortical_b3f41b59-de37-4ef0-b64e-8e1363887e71';
    const scriptSrc = `https://platform.kortical.com/kortical/korticalchat/api/v1/projects/kortical_chat/environments/Production/apps/kortical_chat_ui/clients/${client_id}/chatbots/${chatbot_id}/chatbot_js?${new Date().getTime()}`;

    const new_script = document.createElement('script');
    new_script.type = 'module';
    new_script.async = true;
    new_script.id = script_id;
    new_script.src = scriptSrc;

    const existing_script = document.getElementById(script_id);
    if (existing_script) {
      existing_script.parentNode.replaceChild(new_script, existing_script);
    } else {
      document.getElementsByTagName('head')[0].appendChild(new_script);
    }

    return () => {
      const existing_script = document.getElementById(script_id);
      existing_script && existing_script.parentNode.removeChild(existing_script);
    };
  }, []);
};

export default useChatbotScript;