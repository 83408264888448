import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import Header from './header/header'
import Footer from './footer/footer'
import ScrollTracking from 'helpers/analytics/scroll-tracking'
import { UPDATE_TOTAL_TIMES_SECTION_TRACKING,  UPDATE_PLATFORM_REGISTRATION } from './../store/actions'
import useChatbotScript from '../helpers/chatbot/chatbot'
import '../style/screen.sass'

const Layout = ({
  bodyClass,
  children,
  bodyOverflowHidden,
  updateTotalTimesSectionTracking,
  data_prep_registration_reducer,
  updatePlatformRegistration,
  hide_contact,
  footer_site_map=true,
  footerClass,
  headerClass
}) => {
  useChatbotScript();

  useEffect(() => {
    updateTotalTimesSectionTracking();
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const link_history_storage_name = 'data_prep_link_history';
      const link_history_string = localStorage.getItem(link_history_storage_name);
      const link_history = JSON.parse(link_history_string);
      if (!data_prep_registration_reducer.campaign_referral_code){
        const campaign_referral_code = link_history[1].url;
        updatePlatformRegistration('campaign_referral_code', campaign_referral_code);
      }
      updatePlatformRegistration('link_history', link_history);
    }
  }, [])

  return (
    <React.Fragment>
      <ScrollTracking/>
      <Helmet>
        <body
          className={
            bodyOverflowHidden
              ? `k-no-scroll ${bodyClass}`
              : bodyClass
            } />
      </Helmet>
      <Header className={headerClass} hide_contact={hide_contact}/>
      <main>{children}</main>
      <Footer footer_site_map={footer_site_map} className={footerClass}/>
    </React.Fragment>
)}

const mapStateToProps = state => {
  return {
    bodyOverflowHidden: state.bodyOverflowHidden,
    data_prep_registration_reducer: state.registrationReducer.data_prep[state.registrationReducer.data_prep.length - 1]
  }
}

const mapDispatchToProps = dispatch => ({
  updateTotalTimesSectionTracking: () => {
    dispatch(UPDATE_TOTAL_TIMES_SECTION_TRACKING())
  },
  updatePlatformRegistration: (field, data) => {
    dispatch(UPDATE_PLATFORM_REGISTRATION(field, data))
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Layout)
