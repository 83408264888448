import React from 'react'
import { Link } from 'gatsby'
import ScrollButton from 'components/scroll-button/scroll-button'
import Subheader from 'components/header/subheader'
import './header.sass'

const DesktopHeaderMenu = ({hide_contact}) => {

  return (
    <div className="Header__desktop">
      <Link
            className="k-text--mono ml-4 text-decoration-none"
            to="/case-studies">
            Case Studies
      </Link>
      <Subheader name='Products'
                 children={[
                   { name:'AI Cloud', link:'/getting-started'},
                   { name:'Data Prep', link:'/ml-data-prep'},
                   { name:'AI Consulting', link:'/ai-consulting'},
                   { name:'Documentation / SDK', link:'/docs-content/docs'},
                   { name:'MLOps', link:'/ai'},
                   { name:'Kortical Chat', link:'/ai/kortical-chat'}
                 ]}/>
      <Subheader name='Learn'
                 children={[
                   { name:'Articles', link:'/articles'},
                   { name:'Podcasts', link:'/podcasts'}
                 ]}/>
      <Subheader name='About'
                 children={[
                    { name:'Mission', link:'/about-us'},
                    { name:'Leadership', link:'/about-us#leadership'},
                    { name:'Culture', link:'/about-us#culture'},
                    { name:'Data/IP Security', link:'/about-us#security'},
                    { name:'Sustainability', link:'/about-us#sustainability'},
                    { name:'Careers', link:'/careers'}
                  ]}/>
      <a
        href={process.env.PLATFORM_HOST}
        rel="noopener noreferrer"
        className="k-text--mono ml-4 text-decoration-none">
        Login
      </a>
      { hide_contact
        ? null
        : <ScrollButton
            to="#k-anchor__get-in-touch"
            id="free-trial-header"
            className="Header__free-trial k-text--mono ml-4 text-decoration-none">
            Get in touch
          </ScrollButton>
      }
    </div>
  )
}

export default DesktopHeaderMenu
