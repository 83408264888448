import React from 'react'
import { Link } from 'gatsby'
import { sendClickedEvent } from 'helpers/analytics/event-tracking'
import ScrollButton from 'components/scroll-button/scroll-button'
import './header.sass'

const MobileHeaderMenu = (props) => {
  const hide_contact = props.hide_contact;

  const handleClick = (e) => {
    e.persist();
    sendClickedEvent(e)
    props.onClick(e)
  }
  return (
    <nav className={`HeaderMenu ${props.show ? 'HeaderMenu--show' : ''}`}>
      <div className="HeaderMenu__inner container-fluid d-flex">
        <ul className="list-unstyled mb-0 text-md-right">
          <li>
            <Link
                onClick={handleClick}
                id="NavMobile__/home"
                to="/"
                className=" k-text--mono">
                Home
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/case-studies"
              to="/case-studies"
              className="k-text--mono">
              Case Studies
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/getting-started"
              to="/getting-started"
              className="k-text--mono">
              AI Cloud
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/ml-data-prep"
              to="/ml-data-prep"
              className="k-text--mono">
              Data Prep
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/ai-consulting"
              to="/ai-consulting"
              className="k-text--mono">
              AI Consulting
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/docs"
              to="/docs-content/docs"
              className="k-text--mono">
              Documentation / SDK
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/docs"
              to="/ai"
              className="k-text--mono">
              MLOps
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/docs"
              to="/ai/kortical-chat"
              className="k-text--mono">
              Kortical Chat
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/articles"
              to="/articles"
              className=" k-text--mono">
              Articles
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/podcasts"
              to="/podcasts"
              className=" k-text--mono">
              Podcasts
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/about-us"
              to="/about-us"
              className=" k-text--mono">
              About Us
            </Link>
          </li>
          <li>
            <Link
              onClick={handleClick}
              id="NavMobile__/careers"
              to="/careers"
              className=" k-text--mono">
              Careers
            </Link>
          </li>
          <li>
            <a
              href={process.env.PLATFORM_HOST}
              onClick={handleClick}
              id="NavMobile__/login"
              rel="noopener noreferrer"
              className="k-text--mono">
              Login
            </a>
          </li>

          {hide_contact
            ? null
            : <li className="HeaderMenu__free-trial k-text--mono mt-4 p-2 text-center">
              <ScrollButton
                to="#k-anchor__get-in-touch"
                id="free-trial-header"
                className="text-decoration-none">
                Get in touch
              </ScrollButton>
            </li>
          }
        </ul>
      </div>
    </nav>
  )
}

export default MobileHeaderMenu
