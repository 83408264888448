import React from 'react'
import { Link } from 'gatsby'

const Logo = (props) => {
  if (props.no_link) {
    return (
      <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
        <path
          d="M15 0c3.022 0 5.834.894 8.189 2.432L14.11 15l9.079 12.568A14.925 14.925 0 0115 30C6.717 30 0 23.283 0 15 0 6.717 6.717 0 15 0zm11.479 5.346A14.936 14.936 0 0130 15c0 3.677-1.324 7.044-3.521 9.653L19.506 15zm-10.59 4.27h-5.395l-7.757 10.74h5.395l7.758-10.74z"
          fill={props.white ? '#fff' : '#38D1FF'}
          fillRule="evenodd"
        />
      </svg>
    )
  }

  return (
    <Link
      to="/"
      className={props.className}>
      <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
        <path
          d="M15 0c3.022 0 5.834.894 8.189 2.432L14.11 15l9.079 12.568A14.925 14.925 0 0115 30C6.717 30 0 23.283 0 15 0 6.717 6.717 0 15 0zm11.479 5.346A14.936 14.936 0 0130 15c0 3.677-1.324 7.044-3.521 9.653L19.506 15zm-10.59 4.27h-5.395l-7.757 10.74h5.395l7.758-10.74z"
          fill="#38D1FF"
          fillRule="evenodd"
        />
      </svg>
    </Link>
  )
}

export default Logo
