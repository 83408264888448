import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TOGGLE_MENU, SET_BODY_OVERFLOW } from 'store/actions'
import { Link } from 'gatsby'
import { sendClickedEvent } from 'helpers/analytics/event-tracking'
import MobileHeaderMenu from './mobile-header-menu'
import DesktopHeaderMenu from './desktop-header-menu'
import Logo from './logo'
import MobileMenuButton from './mobile-menu-button'
import './header.sass'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeClass: '',
      openNewsModal: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    document.addEventListener('keydown', this.handleKeydown)
    this.setScrollClass()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('keydown', this.handleKeydown)
  }

  handleKeydown = (event) => {
    if (event.keyCode === 27 && this.props.menuOpen) {
      this.props.toggleMenu()
    }
  }

  handleScroll = () => {
    this.setScrollClass()
  }

  handleClick = (event) => {
    event.preventDefault()
    this.props.toggleMenu(this.props.menuOpen)
  }

  setScrollClass() {
    const activeClass = window.pageYOffset > 0
      ? 'Header--is-scrolled'
      : 'Header--is-scrolled-top'
    if (this.state.activeClass !== activeClass) {
      this.setState({ activeClass })
    }
  }

  getMobileMenuButton() {
    if (typeof window !== 'undefined' && window.innerWidth <= 760 && this.state.openNewsModal) {
      this.setState({openNewsModal: false})
    }
    return (
      <button
        onClick={this.handleClick}
        className="Header__mobile__menu btn btn-link pr-0 text-right">
          <MobileMenuButton menuOpen={this.props.menuOpen} />
      </button>
    )
  }

  getKorticalNavLogo() {
    return (
      <div className="d-flex align-items-center">
        <Logo className="Header__logo" />
        <Link
          id="Nav__/home"
          onClick={(event) => sendClickedEvent(event)}
          to="/"
          className="Header__logo__text k-text--mono ml-3 text-decoration-none">
            Kortical
        </Link>
      </div>
    )
  }

  render() {
    return (
      <section className={`
          Header d-flex align-items-center
          ${this.state.activeClass}
          ${this.props.className}
          ${this.props.menuOpen ? 'Header--is-menu-open' : 'Header--is-menu-closed'}
        `}>
        <div className="Header__bg" />
        <div className="container-fluid">
          <nav className="d-flex align-items-center justify-content-between">
            {this.getKorticalNavLogo()}
            <div>
              {this.getMobileMenuButton()}
              <DesktopHeaderMenu hide_contact={this.props.hide_contact}/>
            </div>
          </nav>
        </div>
        <MobileHeaderMenu
          show={this.props.menuOpen}
          onClick={this.props.toggleMenu}
          hide_contact={this.props.hide_contact} />
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuOpen: state.mainReducer.menuOpen,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleMenu: (menuOpen) => {
      dispatch(TOGGLE_MENU)
      dispatch(SET_BODY_OVERFLOW(!menuOpen))
    },
    setBodyOverflow: (data) => dispatch(SET_BODY_OVERFLOW(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
