import React, { useState } from 'react';
import { Link } from 'gatsby'
import { sendClickedEvent } from 'helpers/analytics/event-tracking'
import DownArrow from 'images/icons/down-arrow.svg.js'
import './header.sass'

const Subheaderchildren = ({ name, link }) => (
  <Link
    id={`Nav__${link}`}
    onClick={(event) => sendClickedEvent(event)}
    className="k-text--mono text-decoration-none text-dark"
    to={link}>
    {name}
  </Link>
)

export default function Subheader({ name, children }) {
  const [ show, setShow ] = useState(false);

  return (
    <div className="Subheader">
      <Link to="#"
            className="k-text--mono ml-4 text-decoration-none py-3"
            id={`Nav__${name.toLowerCase().replace(' ', '_')}`}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onClick={(event) => {
              event.preventDefault();
              sendClickedEvent(event);
              setShow(true);
            }}>
        {name}
        <DownArrow/>
      </Link>
      { show
        ? <div className="Subheader__DesktopNewsModal"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}>
            {children.map((child, index) => {
              return <Subheaderchildren key={index} name={child.name}  link={child.link}/>
            })}
          </div>
        : null
      }
    </div>
  )
}
